
.tr-local {
    background: lightgrey;
}

.MuiTableRow-root.isNewOne {
    background: #6ab56a;
}

.MuiTableRow-root.isRefuseOne {
    opacity: 0.5;
}

@media only screen and (max-width: 600px) {
    .table-responsive thead {
        display: none;
    }

    .table-responsive tr, .table-responsive td, .table-responsive th {
        display: block;
        width: 100%;
        border: none;
    }

    .table-responsivetr {
        padding: 30px 0 30px 0;
        border-bottom: 1px solid #000;
    }
}

.app-alert {
    position: fixed;
    width: 90%;
    bottom: 10px;
    left: 5%;
    right: 5%;
    z-index: 9999;
}

.main-img{
    padding: 10px;
    text-align: center;
}
.main-img img{
    max-width: 300px;
    width: 100%;
}

.form-group{
    padding: 5px 0;
}